@tailwind base;
@tailwind components;
@tailwind utilities;


html,body,#root {
    width:100%;
    margin: 0;
    padding: 0;
    height: 100%;
}

.leaflet-container {
    height: 100%;
}



/*Styles for the legend and othe Pure JS elements*/
body, html{
    height: 100%;
    margin: 0;
}

.buttons{
    width: 100%;
    display: flex;
    align-items: center;
}

.tablink{
    flex-basis: 33.33%;
    padding: 14px 16px;
    font-size: 14px;
    background-color:#72aeb6;
    color: #414142;
    outline: none;
    border: none;
    cursor: pointer;
    font-family: cursive;
}

.tablink:hover {
    background-color:#008cfe;
    border-left: white 2px solid;
    border-right: white 2px solid;
  }

.tabcontent {
    color: green;
    height: 100%;
    background-color:#0567b7;
}

#maps {
    width: 100%;
    height: 100%;
    display: flex;
}

#map-dist , #map-multi ,#map-overlay {
    height: 100%;
    width: 100%;
}


#ip-map-dist, #map-multi ,#map-overlay {
    height: 100%;
    width: 100%;
}

#map-side1 , #map-side2 {
    height: 100%;
    width: 50%;
}

#map-side1 {
    border-right: solid white 2px;
}


#Side-by-Side-Comparison {
    display: flex;
}

#toggle-tab-multi{
    width: 30%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

i {
    width: 50px;
    height: 50px;
    color:red;
    cursor: pointer;
}

#indicators-form {
    width: 40%;
    height: 100%;
    z-index: 1000;
    background-color:#72aeb6;
    position: relative;
    border-right: solid 2px #e86b24;
    border-left: solid 2px #e86b24;
    border-bottom:solid 2px #e86b24 ;
}


#indicators-form-side {
    width: 100%;
    height: 100%;
    z-index: 1000;
    background-color:#72aeb6;
    position: absolute;
    border-right: solid 2px #e86b24;
    border-left: solid 2px #e86b24;
    border-bottom:solid 2px #e86b24 ;
    font-family: cursive;
    font-size: small;

}


#indicators-form-side{
    display: block;
}


#map-multi-dist {
    z-index: 4000;
    position: absolute;
    width: 40%;
    height: 100%;
    
}


/*
#map-side1 div.leaflet-control-container {
    position: relative;
}

*/

#indicators-form{
    display: block;
}

#map1-filters-tab{
    display: block;
    font-family: cursive;
}

#map2-filters-tab{
    display: none;
    font-family: cursive;
}

.ageIndicators{
    display: flex;
}


/*CIRCLE LEGEND STYLES*/
.legend{
    background-color: honeydew;
}

#legend_id_choro{
    border: solid white 5px;
    border-radius: 5px;
    background-color:#72aeb6 ;
    color: #414142;
    font-family: cursive;
    font-size: small;
    
}


#legend_id_choromap1{
    border: solid white 5px;
    border-radius: 5px;
    background-color:#72aeb6 ;
    color: #414142;
    font-family: cursive;
    font-size: small;
    
}

#legend_id_choromap2{
    border: solid white 5px;
    border-radius: 5px;
    background-color:#72aeb6 ;
    color: #414142;
    font-family: cursive;
    font-size: small;
    
}

#legend_id{
    border: solid white 5px;
    border-radius: 5px;
    background-color:#72aeb6 ;
    color: #414142;
    font-family: cursive;
    font-size: small;
    
}

#clusterlegend_id{
    border: solid white 5px;
    border-radius: 5px;
    background-color:#72aeb6 ;
    color: #414142;
    font-family: cursive;
    font-size: small;
    
}

#legend_id_bottom{
    border: solid white 5px;
    font-family:monospace;
    border-radius: 5px;
    background-color:#72aeb6 ;
    color: #414142;
}

#legend_top_overlay{
    border: solid white 5px;
    font-family:monospace;
    border-radius: 5px;
    background-color:#72aeb6 ;
    color: #414142;

}

#legend_id1{
    border: solid white 5px;
    font-family:monospace;
    border-radius: 5px;
    background-color:#72aeb6 ;
    color: #414142;
}

#legend_id2{
    border: solid white 5px;
    font-family:monospace;
    border-radius: 5px;
    background-color:#72aeb6 ;
    color: #414142;
}

#clusterlegend_id1{
    border: solid white 5px;
    border-radius: 5px;
    background-color:#72aeb6 ;
    color: #414142;
    font-family: cursive;
    font-size: small;
    
}


#clusterlegend_id2{
    border: solid white 5px;
    border-radius: 5px;
    background-color:#72aeb6 ;
    color: #414142;
    font-family: cursive;
    font-size: small;
    
}

.symbol_label{
    border-top: solid 2px black;
    flex-basis: 25%;
    width: 100%;
}

.color_circle{
    height: 25.45px;
    width: 25.45px;
    border-radius: 50%;
    border:blue solid 1px;
}

.colour-container {
    align-items: center;
    justify-content: center;
    display: flex;
    margin: 0 auto;
}

.circle_symbol{
    border-radius: 50%;
}

.circle_label{
    flex-basis: 50%;
    height: 100%;
}

.circle_container{
    height: 100%;
    flex-basis: 50%;
    width: 50%;
    justify-content: center;
    align-items: center;
    border-width: solid black 2px;
}

.label_title{
    font-family: cursive;
    font-size: 14px;
}

.legend_text{
    height: 50px;
    width: 300px;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: medium;
}

.containment_box{
    display: flex;
    width: 297px;
    height: 50px;
}

#choro_colour{
    height: 100%;
}

#circel1{
    height:17.4333px;
    width:17.4333px;
    color: #e86b24;
    border: solid blue 1px;
    background-color: #e86b24;
}

#circel2{
    height:33.4333px;
    width:33.4333px;
    color: #e86b24;
    border: solid blue 1px;
    background-color: #e86b24;
}

#circel3{
    height:49.4333px;
    width:49.4333px;
    color: #e86b24;
    border: solid blue 1px;
    background-color: #e86b24;
}

#circel4{
    height: 65.4333px;
    width:65.4333px;
    color: #e86b24;
    border: solid blue 1px;
    background-color: #e86b24;
}

/*Top layer styles*/
#circel5{
    height: 7.45px;
    width: 7.45px;
}
#circel6{
    height: 13.45px;
    width: 13.45px;
}
#circel7{
    height: 19.45px;
    width: 19.45px;
}
#circel8{
    height: 25.45px;
    width: 25.45px;
}

.symbol_label{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}


.legend_choropleth i {
    width: 18px;
    height: 18px;
    float: left;
    margin-right: 8px;
    opacity: 0.7;
}

#choropleth_legend_title{
    height: 50px;
    width: 300px;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: medium;
}

.choro_text{
    width: 150px;
    border-top: 2px solid #008cfe;
    text-align: center;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: medium;
}

.choro_colour{
    width: 150px;
}
.legend_choropleth {
    background-color: honeydew;
    display: flex;
    flex-direction: column;
    flex-basis: 20%;
    height:250px;
    width: 300px;
    
}

/*style for the ip legend*/
#ip-legend{
    width: 400px;
    border: solid white 1px;
    background-color:honeydew;
}

#ip-legendmap1{
    width: 400px;
    border: solid white 1px;
    background-color:honeydew;
}

#ip-legendmap2{
    width: 400px;
    border: solid white 1px;
    background-color:honeydew;
}


/*style for the multivariate choropleth*/

.main-grid-container{
    display: grid;
    grid-template-columns: 25% 75%;
    grid-template-rows: 25% 75%;
    height: 380px;
    width: 380px;
    border: solid white 1px;
    background-color:honeydew;
}

.grid-container{
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
}

#indicator1-title{
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: space-evenly;
}

#indicator2-title{
    display: flex;
    align-items: center;
    white-space:pre-wrap;
    justify-content: space-evenly;
    
}

#indicator1{
    writing-mode: vertical-rl; 
    transform: rotate(-180deg);
    overflow-wrap: anywhere;
    text-align: center;
}

#myindicatorspan2{
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: center;
    border: solid white 1px;
}

#indicator2{
    text-align: center;
    border-top: black solid 4px;
    border-bottom:black solid 4px;
    margin-left: 2px;
    font-size: medium;
    font-family: Georgia, 'Times New Roman', Times, serif;
}


#indicator1{
    text-align: center;
    border-left: black solid 4px;
    border-right:black solid 4px;
    margin-left: 2px;
    font-size: medium;
    font-family: Georgia, 'Times New Roman', Times, serif;
}



.menu-button{
    border-right: white 2px solid;
    background-color: #72aeb6;
}

/*feature label styles*/
.leaflet-tooltip.featureLabel{
    color:black;
    font-size:12px;
    font-weight: 700;
    background-color: transparent;
    border-color: none;
    background: none;
    box-shadow: none;
    margin: 0px;
    cursor: none;
    direction: 'center';
    fill: false;
    opacity: 0;
    border: none;
    padding: 1%;
    text-shadow: 20px 20px 20px white;
    font-family: cursive;
}
 
/*menu styles*/
/*
fieldset {
    font-family: sans-serif;
    border: 5px solid #1F497D;
    background: #fdfcfb;
    border-radius: 5px;
    padding: 15px;
}


fieldset legend {
    background: #1F497D;
    color: #fff;
    padding: 5px 5px ;
    font-size: 16px;
    border-radius: 5px;
    margin-left: 10px;
}


fieldset label{
    border: solid 2px #008cfe;
    border-radius: 5px;
    margin-left: 5px;
    background-color:#008cfe ;
    color: white;
    font-size: 12px;
}

label input{
    margin-left: 5px;
    background-color:#1F497D ;
}

fieldset fieldset{
    margin-bottom: 2px;
}
*/


#submit_clear_button{
    display: flex;
    align-items: center;
}

#Clear-button{
    width: 50%;
    background-color:#1F497D ;
    color: white;
    border-radius: 5px;
    height: 30px;
    outline: white;
}

#submit-btn{
    width: 50%;
    height: 30px;
    background-color:#1F497D ;
    color: white;
    border-radius: 5px;
}

#multi-quarter{
width: 40%;
height: 30px;
color: white;
background-color:#1F497D ;
border-radius: 5px;
margin-bottom: 10px;
}

#multi-level{
width: 40%;
height: 30px;
color: white;
background-color:#1F497D ;
border-radius: 5px;
margin-bottom: 10px;
}


#level_quarter_select{
    padding-left: 20px;
    display: flex;
    justify-content: space-around;
}


#Top-Map{
    width: 50%;
    height: 30px;
    background-color:#1F497D ;
    color: white;
    border-radius: 5px;
}

#Bottom-Map{
    width: 50%;
    height: 30px;
    background-color:#1F497D ;
    color: white;
    border-radius: 5px;
}

.buttons{
    margin-bottom: 5px;
}

legend {
    width: 50%;
}

#Multi_Indicator1 {
    height: 75%;
    margin-top: 20px;
}


#Multi_Indicator1 legend{
    font-family: cursive;
    font-size: small;
    width: 50%;
    height: 5px;
}






#spinner_id div{
    box-sizing: border-box;
    position: absolute;
    width: 100%;
    height: 100%;
    border:10px solid transparent;
    border-top-color: #ad60f5;
    border-radius: 50%;
    animation: spinnerOne 0.8s linear infinite;
}

#spinner_id  div:nth-child(2){
    border:10px solid transparent;
    border-bottom-color: #ad60f5;
    border-radius: 50%;
    animation: spinnerTwo 0.8s linear infinite;
}


#spinner_idmap1  div:nth-child(2){
    border:10px solid transparent;
    border-bottom-color: #ad60f5;
    border-radius: 50%;
    animation: spinnerTwo 0.8s linear infinite;
}

#spinner_idmap2  div:nth-child(2){
    border:10px solid transparent;
    border-bottom-color: #ad60f5;
    border-radius: 50%;
    animation: spinnerTwo 0.8s linear infinite;
}

@keyframes spinnerOne{
    0% {transform: rotate(0deg); border-width: 10px;}
    50% {transform: rotate(180deg); border-width: 1px;}
    100% {transform: rotate(360deg); border-width: 10px;}
}

@keyframes spinnerTwo{
    0% {transform: rotate(0deg); border-width: 10px;}
    50% {transform: rotate(180deg); border-width: 1px;}
    100% {transform: rotate(360deg); border-width: 10px;}
}

#spinner_idmap1 div{
    box-sizing: border-box;
    position: absolute;
    width: 100%;
    height: 100%;
    border:10px solid transparent;
    border-top-color: #ad60f5;
    border-radius: 50%;
    animation: spinnerOne 0.8s linear infinite;
    z-index: 4;
}

#spinner_idmap2 div{
    box-sizing: border-box;
    position: absolute;
    width: 100%;
    height: 100%;
    border:10px solid transparent;
    border-top-color: #ad60f5;
    border-radius: 50%;
    animation: spinnerOne 0.8s linear infinite;
}


#spinner_idmap3 div{
    box-sizing: border-box;
    position: absolute;
    width: 100%;
    height: 100%;
    border:10px solid transparent;
    border-top-color: #ad60f5;
    border-radius: 50%;
    animation: spinnerOne 0.8s linear infinite;
}

#spinner_idmap4 div{
    box-sizing: border-box;
    position: absolute;
    width: 100%;
    height: 100%;
    border:10px solid transparent;
    border-top-color: #ad60f5;
    border-radius: 50%;
    animation: spinnerOne 0.8s linear infinite;
}



@media screen and (min-width: 1600px) {
    #bar{
        height:350px;
        width: 350px;
    }

    #trends{
        height:350px;
        width: 350px;
        right: 95%;
    }
    #donut{
        height:350px;
        width: 350px; 
        top: 400px;
    }
    #total{
        height:400px;
        width: 400px;
    }
    #spinner_id{
    width: 200px;
    height: 200px;
    position: absolute;
    left:1000px;
    bottom: 600px;
    }


    #spinner_idmap1{
        width: 100px;
        height: 100px;
        position: absolute;
        left:500px;
        bottom: 300px;
    }
    #spinner_idmap2{
        width: 100px;
        height: 100px;
        position: absolute;
        left:500px;
        bottom: 300px;
    }
    
    #spinner_idmap3{
        width: 100px;
        height: 100px;
        position: absolute;
        left:500px;
        bottom: 300px;
    }
    
    #spinner_idmap4{
        width: 100px;
        height: 100px;
        position: absolute;
        left:500px;
        bottom: 300px;
    }


}

@media screen and (max-width: 1600px) {
    #bar{
        height: 300px;
        width: 300px;
    }

    #trends{
        height:320px;
        width: 300px;
    }
    #donut{
        height:300px;
        width: 300px;
        top: 350px;
        right: 800px;
    }
    #total{
        height:300px;
        width: 300px;
    }
    #spinner_id{
    width: 100px;
    height: 100px;
    position: absolute;
    left:600px;
    bottom: 300px;
    }

    #spinner_idmap1{
        width: 100px;
        height: 100px;
        position: absolute;
        left:200px;
        bottom: 100px;
    }
    #spinner_idmap2{
        width: 100px;
        height: 100px;
        position: absolute;
        left:200px;
        bottom: 100px;
    }
    
    #spinner_idmap3{
        width: 100px;
        height: 100px;
        position: absolute;
        left:200px;
        bottom: 100px;
    }
    
    #spinner_idmap4{
        width: 100px;
        height: 100px;
        position: absolute;
        left:200px;
        bottom: 100px;
    }
}


#choro-cluster-legend{
    background-color:honeydew ;
    height: 250px;
    width: 350px;
    font-family: cursive;
    color: black;
}



.main-grid-container{
    display: grid;
    grid-template-columns: 25% 75%;
    grid-template-rows: 25% 75%;
    height: 380px;
    width: 380px;
    border: solid white 1px;
    background-color:honeydew;
    
}


#dist-toggle{
    border-left: white 2px solid;
    border-right: white 2px solid;
}

#legend_close{
   cursor:pointer;
}



#legend_closemap1{
    cursor:pointer;
 }


 #legend_closemap2{
    cursor:pointer;
 }

 #quarter-mark{
    border: 2px solid;
    background-color: #414142;
    color: white;
    opacity: 70%;
    font-weight: 100;
    padding: 10px 10px;
    font-family: cursive;
 }

 #quarter-markmap1{
    border: 2px solid;
    background-color: #414142;
    color: white;
    opacity: 70%;
    font-weight: 100;
    padding: 10px 10px;
    font-family: cursive;
 }


 #quarter-markmap2{
    border: 2px solid;
    background-color: #414142;
    color: white;
    opacity: 70%;
    font-weight: 100;
    padding: 10px 10px;
    font-family: cursive;
 }



 .glassy-table {
    width: 100%;
    background: rgba(255, 255, 255, 0.2); /* Transparent white background */
    backdrop-filter: blur(10px); /* Blurs background behind the table */
    border-radius: 12px; /* Rounded corners */
    overflow: hidden; /* Hide overflow on rounded corners */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Soft shadow */
  }
  
  .glassy-table th,
  .glassy-table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3); /* Subtle borders */
    color: #fff; /* Light text color */
  }
  
  .glassy-table th {
    background: rgba(255, 255, 255, 0.3); /* Slightly darker background for headers */
    font-weight: bold;
  }
  
  .glassy-table tbody tr:hover {
    background: rgba(255, 255, 255, 0.1); /* Highlight row on hover */
  }
  


  .table-fixed {
    width: 100%; /* Optional: Set to 100% or any width you prefer */
    border-collapse: collapse; /* Optional: To avoid spacing between cells */
}

.table-fixed th,
.table-fixed td {
    height: 50px; /* Set to your desired fixed height */
    overflow: hidden; /* Prevent content overflow */
    text-overflow: ellipsis; /* Optional: Use ellipsis for overflowing text */
    white-space: nowrap; /* Prevent text from wrapping */
}

.table-fixed th {
    background-color: #1C1C1C; /* Header background color */
    color: white; /* Header text color */
}


#datatable {
    border-collapse: collapse;
    width: 100%;
  }
  
  #datatable td, #datatable th {
    border: 1px solid #ddd;
    padding: 12px;
  }
  
  #datatable tr:nth-child(even){background-color: rgba(249, 250, 251, 1);}
  
  #datatable tr:hover {background-color: rgba(243, 244, 246, 1);}
  
  #datatable th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #1C1C1C;
    color: white;
  }




  /* styles.css */
.action-buttons-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
  }
  
  .action-button {
    flex: 1;
    color: white;
    background-color: #1C1C1C;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    padding: 6px 12px;
    font-size: 14px;
    transition: transform 0.2s ease-in-out;
    cursor: pointer;
  }
  
  .action-button:hover {
    transform: scale(1.1);
  }



  .pagination-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px; /* Adds some space above the pagination */
    gap: 8px; /* Optional: Adjust spacing between buttons */
  }
  
  .action-button {
    flex: 1;
    color: white;
    background-color: #1C1C1C;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    padding: 6px 12px;
    font-size: 14px;
    text-align: center;
    transition: transform 0.2s ease-in-out;
    cursor: pointer;
  }
  
  .action-button:hover {
    transform: scale(1.1);
  }
  
  .action-button:disabled {
    background-color: #555555; /* Dimmed background for disabled buttons */
    cursor: not-allowed;
  }
  
  .pagination-indicator {
    text-align: center;
    font-size: 14px;
    margin-top: 8px; /* Adds a bit of space between buttons and text */
  }